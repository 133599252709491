import { get } from "lodash";
import React from "react";
import {
    getParams, navigateTo, removeParams, setParams
} from "../../utils/location";
import AppContainer from "../AppContainer";
import ChargeCard from "../ChargeCard";
import RechargeCardView from "../RechargeCardView";
import * as styles from "./index.module.scss";
import { PAYMENT_ID } from "../../api-services/constants";
import StripeProvider from "../StripeProvider";
import { ElementsConsumer } from "@stripe/react-stripe-js";

const WrappedRechargeCardView = (props) => {
  const { business, paymentTypeDetails, order } = props;

  const chargeCardBasePrice = get(business, "openChargeCard.price");
  
  if (
    paymentTypeDetails?.paymentType === PAYMENT_ID.STRIPE &&
    !order.orderPlaced &&
    chargeCardBasePrice.amount
  ) {
    const options = {
      mode: "payment",
      amount: Math.floor(chargeCardBasePrice.amount * 100),
      currency: business.currency.toLowerCase(),
      paymentMethodCreation: "manual",
    };

    return (
      <StripeProvider
        options={options}
        paymentTypeDetails={paymentTypeDetails}
      >
        <ElementsConsumer>
          {({ stripe, elements }) => (
            <RechargeCardView
            {...{ stripe, elements, ...props }}
          />
          )}
        </ElementsConsumer>
      </StripeProvider>
    );
  }

  return <RechargeCardView {...props} />;
}

export default class extends React.Component {
  componentDidMount() {
    const { user, paymentTypeDetails } = this.props;
    if (user.loggedIn) {
      const paymentTypeIdentifier = get(paymentTypeDetails, "paymentType");
      console.log("loading payment methods and loyalty profile...");
      this.props.loadPaymentMethods(paymentTypeIdentifier);
      this.props.loadLoyaltyProfile(true);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.user.rechargeCard.sent &&
      this.props.user.rechargeCard.sent
    ) {
      this.nextPageTransition = setTimeout(() => {
        this.props.loadLoyaltyProfile(true);
        navigateTo(removeParams(this.props.location, "rechargeCard"));
      }, 200);
    }
  }

  componentWillUnmount() {
    this.props.rechargeCardReset();
    clearTimeout(this.nextPageTransition);
  }

  handleChangeLocation = (to) => () => {
    const { location, paymentTypeDetails } = this.props;
    this.props.rechargeCardReset();
    if (to !== "rechargeCard") {
      navigateTo(to);
    } else {
      navigateTo(setParams(location, { [to]: true }));

      if (to === "rechargeCard") {
        const paymentTypeIdentifier = get(paymentTypeDetails, "paymentType");
        this.props.loadPaymentMethods(paymentTypeIdentifier);
      }
    }
  };

  render() {
    const {
      T,
      location,
      appStyles = {},
      business,
      business: { currencySymbol },
      user,
      order,
      businessAppConfiguration: {
        idRequired,
        hasCashback,
        loadCardEnabled,
        hasPointBank,
      },
      loadLoyaltyProfile,
      keyboardOpen,
      paymentTypeDetails,
    } = this.props;

    const params = getParams(location);

    const isInRechargeState = Boolean(params.rechargeCard);
    const isCardRecentlyCharged = get(user, "rechargeCard.sent");

    return isInRechargeState ? (
      <WrappedRechargeCardView
        T={T}
        appStyles={appStyles}
        user={user}
        location={location}
        idRequired={idRequired}
        keyboardOpen={keyboardOpen}
        currencySymbol={currencySymbol}
        paymentTypeDetails={paymentTypeDetails}
        loadLoyaltyProfile={loadLoyaltyProfile}
        rechargeCard={this.props.rechargeCard}
        classNames={styles.DesktopGutters}
        order={order}
        business={business}
      />
    ) : (
      <AppContainer.Content appStyles={appStyles}>
        <ChargeCard
          appStyles={appStyles}
          T={T}
          hasCashback={hasCashback}
          hasChargeCard={loadCardEnabled}
          hasPointBank={hasPointBank}
          user={user}
          order={order}
          onError={loadLoyaltyProfile}
          onRecharge={this.handleChangeLocation("rechargeCard")}
          onGoBack={this.handleChangeLocation("/order/?checkoutOpen=true")}
          currencySymbol={currencySymbol}
          classNames={styles.DesktopGutters}
          isCardRecentlyCharged={isCardRecentlyCharged}
        />
      </AppContainer.Content>
    );
  }
}
