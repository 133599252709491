import { castArray, map, find, get, first } from "lodash";

export const appfrontCdnRoot =
  process.env.GATSBY_APP_ENV === "production"
    ? "https://media.appfront.app"
    : "https://media-sandbox.appfront.app";

export const cdnRoot = `${appfrontCdnRoot}/businesses/${
  process.env.GATSBY_BUSINESS_ID
}`;

export const getWebsiteHeroImage = (objectKey) => `${appfrontCdnRoot}/${objectKey}`

const mediaVersion = 4;
const appMediaPath = "app-media";
const fitType = {
  contain: "contain",
};

export const mediaTypes = {
  // media folders
  menuItem: 0,
  deal: 1,
  menuCategory: 2,
  branch: 3,
  charity: 4,
  coupon: 5,
  // top level media:
  temporarySplashScreen: 6,
  defaultItemImage: 7,
  launchImage: 8,
  logo: 9,
  appIcon: 10,
  webAppBackground: 11,
  webMobileAppBackground: 12,
  webAppHeader: 13,
  webMobileAppHeader: 14,
  cardBackgroundImage: 15,
  additionalImageMenuItem: 16,
  menuItemModifier: 17,
};

export const promotionMediaType = {
  image: "image",
  videoUrl: "videoUrl",
};

// paramOptions: [[width?, height]| width, fit?] | [[[width?, height]| width, fit?]]

const menuItemParamOptions = [[600, fitType.contain]];

const mediaTypeMeta = {
  [mediaTypes.menuItem]: {
    pathPrefix: "menu-items",
    paramOptions: menuItemParamOptions,
  },
  [mediaTypes.menuItemModifier]: {
    pathPrefix: "menu-item-modifiers",
    paramOptions: menuItemParamOptions,
  },
  [mediaTypes.additionalImageMenuItem]: {
    pathPrefix: "menu-items",
    paramOptions: 'no_adding_suffix',
  },
  [mediaTypes.image]: {
    pathPrefix: "images",
    paramOptions: 'no_adding_suffix',
  },
  [mediaTypes.menuCategory]: {
    pathPrefix: "menu-categories",
    paramOptions: menuItemParamOptions,
  },
  [mediaTypes.deal]: {
    pathPrefix: "deals",
    paramOptions: menuItemParamOptions,
  },
  [mediaTypes.branch]: {
    pathPrefix: "branches",
    paramOptions: menuItemParamOptions,
  },
  [mediaTypes.charity]: {
    pathPrefix: "charities",
    paramOptions: menuItemParamOptions,
  },
  [mediaTypes.coupon]: {
    pathPrefix: "coupons",
    paramOptions: menuItemParamOptions,
  },
  [mediaTypes.temporarySplashScreen]: {
    pathPrefix: appMediaPath,
    paramOptions: [[900, fitType.contain]],
    mediaId: "temporarySplashScreen",
  },
  [mediaTypes.defaultItemImage]: {
    pathPrefix: appMediaPath,
    paramOptions: [[400, fitType.contain]],
    mediaId: "defaultItemImage",
  },
  [mediaTypes.launchImage]: {
    pathPrefix: appMediaPath,
    paramOptions: [[900, fitType.contain]],
    mediaId: "launchImage",
  },
  [mediaTypes.logo]: {
    pathPrefix: appMediaPath,
    paramOptions: [[250, fitType.contain]],
    mediaId: "logo",
  },
  [mediaTypes.appIcon]: {
    pathPrefix: appMediaPath,
    paramOptions: [[64, fitType.contain], [48, fitType.contain]],
    mediaId: "appIcon",
  },
  [mediaTypes.webAppBackground]: {
    pathPrefix: appMediaPath,
    paramOptions: [[1920, fitType.contain]],
    mediaId: "webAppBackground",
  },
  [mediaTypes.webMobileAppBackground]: {
    pathPrefix: appMediaPath,
    paramOptions: [[768, fitType.contain]],
    mediaId: "webMobileAppBackground",
  },
  [mediaTypes.webAppHeader]: {
    pathPrefix: appMediaPath,
    paramOptions: [[[, 300], fitType.contain]],
    mediaId: "webAppHeader",
  },
  [mediaTypes.webMobileAppHeader]: {
    pathPrefix: appMediaPath,
    paramOptions: [[[, 300], fitType.contain]],
    mediaId: "webMobileAppHeader",
  },
  [mediaTypes.cardBackgroundImage]: {
    pathPrefix: appMediaPath,
    paramOptions: [[400, fitType.contain]],
    mediaId: "cardBackgroundImage",
  },
};

const getMediaForParams = ([dimensions, fit], options = {}) => {
  const [width, height] = castArray(dimensions);
  return `${width || "_"}-${height || "_"}${
    fit ? "-" + fitType[fit] : ""
  }_v${mediaVersion}${options.fallbackVersion ? "_jpeg" : ""}${
    options.withWidth && width ? " " + width + "w" : ""
  }`;
};

export const getAppMedia = (mediaType, appStyles) =>
  find(appStyles.appMedia, { mediaId: mediaTypeMeta[mediaType].mediaId });

export const getImageSrcFor = (mediaType, key, params, options = {}) =>
  `${cdnRoot}/${mediaTypeMeta[mediaType].pathPrefix}/${key}/${getMediaForParams(
    params,
    options,
  )}`;

export const getAppMediaSrc = (mediaType, appStyles, size) => {
  const key = get(getAppMedia(mediaType, appStyles), "imageKey");
  const paramOptions = mediaTypeMeta[mediaType].paramOptions;

  if (size) { // specific size of the image
    return key &&
      getImageSrcFor(mediaType, key, find(paramOptions, params => params[0] === size));
  }

  return ( // default size of the image
    key &&
    getImageSrcFor(mediaType, key, first(paramOptions))
  );
};

export const getAppMediaSrcSet = (mediaType, appStyles) => {
  return getSrcSet(mediaType, get(getAppMedia(mediaType, appStyles), "imageKey"));
}
  

export const getAppMediaPreview = (mediaType, appStyles) =>
  get(getAppMedia(mediaType, appStyles), "imagePreview");

export const getSrcSet = (mediaType, key, fallbackVersion) => {
  if (key && mediaTypeMeta[mediaType]?.paramOptions === 'no_adding_suffix') {
    return `${cdnRoot}/${mediaTypeMeta[mediaType].pathPrefix}/${key}`
  }

  if (key && mediaTypeMeta[mediaType]?.paramOptions) {
    return map(mediaTypeMeta[mediaType].paramOptions, (params) =>
      getImageSrcFor(mediaType, key, params, {
        withWidth: true,
        fallbackVersion,
      }),
    ).join(", ");
  }
}
  
