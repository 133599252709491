import React from "react";
import { find, indexOf, isEmpty } from "lodash";
import * as styles from "./index.module.scss";
import { priceForSequenceOption } from "../../utils/menuItemOrderData";

const ComponentOptionPrice = ({
  componentOption,
  addPlusSign,
  appStyles,
  currencySymbol,
  posInnerMenuId,
  orderItemComponent,
}) => {
  const {
    maxNumOfMarkedOptionsIncluded,
    chosenOrderItemComponentOptions,
    priceAdditional,
  } = orderItemComponent;

  const chosenOptionPosInnerMenuItem = find(
    componentOption.posInnerMenuSpecificPrices,
    { posInnerMenuId }
  );

  const componentOptionPrice =
    chosenOptionPosInnerMenuItem &&
    typeof chosenOptionPosInnerMenuItem.price === "number"
      ? chosenOptionPosInnerMenuItem.price
      : componentOption.price;

  const indexInChosen = indexOf(
    chosenOrderItemComponentOptions,
    componentOption.id
  );
  const showPriceAdditional =
    !isEmpty(orderItemComponent.chosenOrderItemComponentOptions) &&
    orderItemComponent.chosenOrderItemComponentOptions.length >=
      maxNumOfMarkedOptionsIncluded &&
    (indexInChosen >= maxNumOfMarkedOptionsIncluded || indexInChosen === -1);

  const price =
    (componentOptionPrice || 0) +
    ((showPriceAdditional && priceAdditional) || 0) +
    priceForSequenceOption(componentOption, orderItemComponent);

  if (appStyles.hidePlusSign && price < 0) {
    return (
      <span className={styles.ComponentOptionPrice}>
        {" "}
        {currencySymbol}
        {price * -1} off
      </span>
    );
  }
  return price > 0 ? (
    <span className={styles.ComponentOptionPrice}>
      {addPlusSign && "+"}
      {currencySymbol}
      {appStyles.pricesFixedPoint
        ? price.toFixed(appStyles.pricesFixedPoint)
        : price}
    </span>
  ) : (
    null
  );
};

export default ComponentOptionPrice;