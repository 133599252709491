import React, { useEffect } from "react";
import { get } from "lodash";
import GiftCardView from "./GiftCardView";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import { ElementsConsumer } from "@stripe/react-stripe-js";
import { PAYMENT_ID } from "../../api-services/constants";
import StripeProvider from "../StripeProvider";

const GiftCardViewWrapper = (props) => {
  const {
    paymentTypeDetails,
    paymentTypeDetailsForWallets,
    pageContext: { business },
    user: {
      giftCardState: {
        giftCardAmount = 1
      }
    },
    user,
    openPayment
  } = props;

  useEffect(() => {
    if (paymentTypeDetails.paymentType !== PAYMENT_ID.TOAST_HOSTED) return;

    openPayment({
      amount: Math.floor(giftCardAmount * 100),
      user,
      paymentTypeIdentifier: paymentTypeDetails?.paymentType,
      isGiftCard: true,
    });
  }, [giftCardAmount]);

  if (
    (paymentTypeDetailsForWallets ||
    get(paymentTypeDetails, "paymentType") === PAYMENT_ID.STRIPE)
  ) {
  
    const options = {
      mode: "payment",
      amount: Math.floor(giftCardAmount * 100),
      currency: business.currency.toLowerCase(),
      paymentMethodCreation: "manual",
    };

    return (
      <StripeProvider
      options={options}
      paymentTypeDetails={paymentTypeDetailsForWallets || paymentTypeDetails}
    >
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <GiftCardView {...{ stripe, elements, ...props }} />
        )}
      </ElementsConsumer>
    </StripeProvider>
    )
  }

  return <GiftCardView {...props} />;
};

export default GiftCardViewWrapper;

export const GiftCardViewWithRecaptcha = withGoogleReCaptcha(GiftCardViewWrapper);
