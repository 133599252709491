import React from "react";
import { makeStyles } from "../AppContainer/mui-theme";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import DecreaseIcon from "@mui/icons-material/Remove";
import IncreaseIcon from "@mui/icons-material/Add";
import keyCode from "keycode";
import { identity, isNumber } from "lodash";

const QuantityInput = React.forwardRef(({
  T = identity,
  appStyles = {},
  onChange,
  min,
  max,
  readOnly,
  precision,
  withPaper,
  size = "medium",
  value,
  rounded
}, ref) => {
  const { classes } = useStyles(rounded);
  const [currentValue, setCurrentValue] = React.useState(value);

  const handleOnChange = onChange;

  React.useEffect(() => {
    if (value !== currentValue) {
      setCurrentValue(value);
    }
  }, [value]);

  const increament = (e) => {
    const nextValue = Math.min(max, currentValue + 1);

    setCurrentValue(nextValue);
    handleOnChange(nextValue);
  };

  const decreament = (e) => {
    const nextValue = Math.max(min, currentValue - 1);
    setCurrentValue(nextValue);
    handleOnChange(nextValue);
  };

  const handleKeyDown = (e) => {
    if (keyCode.isEventKey(e, "up")) {
      return increament(e);
    }
    if (keyCode.isEventKey(e, "down")) {
      return decreament(e);
    }
  };

  const handleNumberInputChanged = (e) => {
    const nextValue = parseInt(e.target.value);
    if (nextValue && nextValue <= max && nextValue >= min) {
      setCurrentValue(nextValue);

      handleOnChange(nextValue);
    }
  };
  const Wrapper = withPaper ? Paper : "form";

  const buttonHeight = size ==="large" ? 44 : size === "medium" ? 34 : 24;
  const buttonStyles = {
        padding: 2,
        border: `2px solid ${appStyles.actionColor}`,
        borderRadius: "50%",
        backgroundColor: appStyles.actionColor,
        width:  buttonHeight,
        height: buttonHeight,
        color: "#FFFFFF"
      }

  const disbaledButtonStyles = {
        ...buttonStyles,
        opacity: 0.3,
      }
  const textMargin = size ==="large" ? 10 : size === "medium" ? 7 : 5;
  const fontSize = _.min(buttonHeight - 4, 20);
  const iconHeight = size ==="large" ? 24 : size === "medium" ? 18 : 14;
  const inputStyles ={ 
    color: appStyles.actionColor, 
    width: buttonHeight, 
    fontSize,
    marginLeft: textMargin, 
    marginRight: textMargin
  }

  const decreamentDisabled =
    isNumber(min) && isNumber(currentValue) && min == currentValue;
  const increamenteDisabled =
    isNumber(max) && isNumber(currentValue) && max == currentValue;
  return (
    <Wrapper
      {...withPaper && { component: "form" }}
      className={classes.root}
      style={{ display: "flex", ...withPaper && { padding: "8px 12px" } }}
    >
      <IconButton
        className={classes.iconButton}
        aria-label={T("Decrement Quantity")}
        disabled={decreamentDisabled}
        size={size}
        style={min < currentValue ? buttonStyles : disbaledButtonStyles}
        onClick={decreament}
      >
        <DecreaseIcon style={{width: iconHeight, height: iconHeight}}/>
      </IconButton>
      <InputBase
        className={classes.input}
        value={parseInt(currentValue)}
        onChange={handleNumberInputChanged}
        onKeyDown={handleKeyDown}
        inputProps={{
          "aria-label": T("Item Quantity"),
          type: "text",
          inputMode: "numeric",
          pattern: "[0-9]*",
          style: inputStyles,
          readOnly,
        }}
        ref={ref}
      />
      <IconButton
        className={classes.iconButton}
        aria-label={T("Increment Quantity")}
        disabled={increamenteDisabled}
        size={size}
        style={max > currentValue ? buttonStyles : disbaledButtonStyles}
        onClick={increament}
      >
        <IncreaseIcon style={{width: iconHeight, height: iconHeight}}/>
      </IconButton>
    </Wrapper>
  );
});

const useStyles = makeStyles()((theme) => ({
  root: {
    background: "inherit",
    color: "inherit",
    display: "flex",
    alignItems: "center",
    fontFamily: "inherit",
    flexDirection: "row",
  },
  input: {
    fontFamily: "inherit",
    color: "inherit",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    "& > input": {
      textAlign: "center",
    },
    "& .MuiInputBase-input": {
      padding: 0,
    }
  },
  iconButton: {
    color: "inherit",
    width: 44,
    height: 44,
    padding: 5
  },
}));

export default QuantityInput;
