import React from "react";
import { omit } from "lodash";
import withTranslation from "../hocs/withTranslation";
import { connect } from "react-redux";
import * as s from "../store/selectors";

import CategoriesView from "../components/categories-view";

const CategoriesPage = (props) => <CategoriesView {...props} />;

const mapStateToProps = (state, props) => {
  const {
    user,
    header,
    order,
    menuItemsConfiguration,
    app,
    giftRedeem,
  } = state;
  return {
    appStyles: s.getStyles(state, props),
    menuData: s.getAvailableMenuData(state, props),
    locations: omit(
      s.getAvailableLocations(state, props),
      "branchesAvailability",
    ),
    currentBranch: s.getOrderBranch(state, props),
    deals: s.getDeals(state, props),
    branchesAvailability: s.getBranchesAvailability(state, props),
    order,
    giftRedeem,
    menuItemsConfiguration,
    user,
    header,
    app,
  };
};

export default connect(
  mapStateToProps,
)(withTranslation(CategoriesPage));
