import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBranchesAvailability,
  getLatestOrders,
} from "../../../store/selectors";
import {
  Avatar,
  Box,
  Card,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import Slider from "react-slick";
import { makeStyles } from "../../AppContainer/mui-theme";
import Image from "../../image";

import "../slick-theme.css";
import { get, isEmpty, take, find, join, map, filter } from "lodash";
import { mediaTypes } from "../../../utils/media";
import NoImageRenderer from "../../no-image-renderer";
import { getParams, navigateTo, setParams } from "../../../utils/location";
import { setOrderForReorder as setOrderForReorderAction } from "../../../store/order/actions";
import { openCheckoutView } from "../../../store/app/actions";
import {
  loadBranchAvailabiltyIfNeeded,
  loadDeliveryAreas,
} from "../../../store/locations/actions";

const ReorderWidget = (props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const { appStyles, widget, pageContext } = props;
  const latestOrders = useSelector((state) => getLatestOrders(state, props));
  const branchesAvailability = useSelector((state) =>
    getBranchesAvailability(state, props)
  );
  const isMobile = useSelector((state) => state.app.isMobile);

  const sliderRef = useRef(null);

  useEffect(() => {
    dispatch(loadBranchAvailabiltyIfNeeded());
  }, []);

  const settings = {
    infinite: false,
    speed: 1000,
    slidesToShow: 1.37, // to show a part of next slide
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    focusOnSelect: true,
  };

  const setOrderForReorder = (order, firstAvailableDate) =>
    dispatch(
      setOrderForReorderAction(order, firstAvailableDate, pageContext.menuData)
    );

  const loadDeliveryAreasIfNeededAndStartReorder = (order) => () => {
    if (order.servingOption.needsAddress) {
      if (!order.deliveryAddress) {
        startReorder(order);
        navigateTo("/find-location");
      } else {
        const geoPoint = order.deliveryAddress.geoPoint;
        dispatch(
          loadDeliveryAreas({
            lat: geoPoint.latitude,
            lng: geoPoint.longitude,
            servingOptionId: order.servingOption.id,
            deliveryAddress: order.deliveryAddress,
          })
        );
      }
    } else {
      startReorder(order);
    }
  };

  const startReorder = (order) => {
    const branchAvailability = get(branchesAvailability, `${order.branchId}`);
    const hasTableCodes = get(order, "servingOption.hasTableCodes");
    if (branchAvailability && !hasTableCodes) {
      const firstAvailableDate =
        get(branchesAvailability, `${order.branchId}.availableFrom`) ||
        (get(branchesAvailability, `${order.branchId}.availability`) ===
          "AVAILABLE_NOW" &&
          new Date());

      setOrderForReorder(order, firstAvailableDate);

      dispatch(openCheckoutView());
      navigateTo(
        setParams("/order", { ...getParams(location), fromReorder: true, checkoutOpen: true })
      );
    } else {
      setOrderForReorder(order);
      const preSelectedBranchId = hasTableCodes && order.branchId;
      navigateTo(
        setParams("/find-location", {
          ...getParams(location),
          fromReorder: true,
          preSelectedBranchId,
        })
      );
    }
  };

  const renderCarusleMenuItem = (item, index) => {
    const { sourceItem = {}, isPlaceholder } = item;

    const imageSizesProps = {
      sizes: "38px",
      wrapperStyle: {
        height: 38,
        width: 38,
        position: "absolute",
        top: "12px",
        right: `${index * 20 + 12}px`, // Adjust this for horizontal stacking shift
        borderRadius: "50%", // Optional, to give rounded corners
        border: "2px solid white",
        boxShadow: "0 0 10px rgba(0,0,0,0.5)",
        zIndex: 10 - index, // Ensures proper stacking order
        background: "white",
      },
    };

    if (isPlaceholder) {
      return (
        <div
          style={{
            ...imageSizesProps.wrapperStyle,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#e5e1e0",
          }}
        >
          <Add sx={{ width: 24, height: 24, color: "white" }} />
        </div>
      );
    }

    return get(sourceItem, "imageKey") ? (
      <Image
        mediaKey={sourceItem.imageKey}
        mediaType={
          sourceItem.useCategoryImage
            ? mediaTypes.menuCategory
            : mediaTypes.menuItem
        }
        imagePreview={sourceItem.imagePreview}
        {...imageSizesProps}
      />
    ) : (
      <NoImageRenderer {...imageSizesProps} appStyles={props.appStyles} />
    );
  };

  const getBranchAddress = (order) => {
    const branch = find(
      pageContext.branches,
      ({ id }) => id === order.branchId
    );

    const deliveryAddress = get(branch, "deliveryAddress");

    if (
      !isEmpty(deliveryAddress) &&
      deliveryAddress.city &&
      deliveryAddress.street &&
      deliveryAddress.number
    ) {
      return `${deliveryAddress.number} ${deliveryAddress.street}, ${
        deliveryAddress.city
      }`;
    }

    return get(branch, "address");
  };

  const sliderContent = take(latestOrders, 5).map((order, index) => (
    <div
      style={{ height: "100%" }}
      key={index}
      onClick={loadDeliveryAreasIfNeededAndStartReorder(order)}
    >
      <Paper elevation={3} sx={{ margin: "5px", borderRadius: "10px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ padding: "12px" }}>
            <Typography
              style={{ margin: 0, fontWeight: "bolder", fontSize: "12px" }}
              variant="h6"
            >
              {order.servingOption.servingOptionTag}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="body1"
                component={"span"}
                sx={{
                  color: appStyles.textColor,
                  fontSize: "10px",
                  height: "28px",
                  width: "116px",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {join(
                  map(filter(order.orderItems, (item) => item), (item) =>
                    get(item, "configuredMenuItemOrderData.itemName")
                  ),
                  ", "
                )}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              position: "relative",
              width: "40%",
            }}
          >
            {(order.orderItems.length <= 3
              ? take(order.orderItems, 3)
              : [{ isPlaceholder: true }, ...take(order.orderItems, 3)]).map(
                  (item, index) => renderCarusleMenuItem(item, index)
                )}
          </Box>
        </Box>
        <Divider />
        <Grid
          container
          sx={{ padding: "8px 12px" }}
          display="flex"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={10} display="flex" alignItems="center" sx={{ width: "182px" }}>
            <Typography
              component="span"
              sx={{
                color: appStyles.textColor,
                fontSize: "10px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {getBranchAddress(order)}
            </Typography>
            <Typography
              component="span"
              sx={{
                color: appStyles.textColor,
                fontSize: "10px",
                padding: "0 2px",
              }}
            >
              {" · "}
            </Typography>
            <Typography
              component="span"
              sx={{ color: appStyles.textColor, fontSize: "10px" }}
            >
              {`${pageContext.business.currencySymbol}${Number(
                get(order, "finalPrice")
              ).toFixed(2)}`}
            </Typography>
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="right">
            <Avatar
              sx={{
                background: appStyles.actionColor,
                width: 24,
                height: 24,
              }}
            >
              <Add sx={{ width: 16, height: 16 }} />
            </Avatar>
          </Grid>
        </Grid>
      </Paper>
    </div>
  ));

  const DesktopWrapper = ({ children }) =>
    !isMobile ? (
      <Card style={{ borderRadius: appStyles.cardBorderRadius }}>
        {children}
      </Card>
    ) : (
      children
    );

  return (
    <DesktopWrapper>
      <Box>
        <Typography
          gutterBottom
          variant="h5"
          sx={{ fontWeight: "bolder", fontSize: "large" }}
        >
          {!isEmpty(widget.title) ? widget.title : "Order Again"}
        </Typography>
        {!isEmpty(widget.subtitle) && (
          <Typography gutterBottom variant="caption" component="h3">
            {widget.subtitle}
          </Typography>
        )}
        {!isEmpty(widget.content) && (
          <Typography variant="body1" component="p">
            {widget.content}
          </Typography>
        )}

        <Slider ref={sliderRef} {...settings} className={classes.reorderSlider}>
          {sliderContent}
        </Slider>
      </Box>
    </DesktopWrapper>
  );
};

const desktopCardPromotionWidth = 376;

const useStyles = makeStyles()((theme) => ({
  reorderSlider: {
    height: "100%",
    maxHeight: `calc(100vw - ${theme.spacing(25)})`,
    width: `calc(100vw - ${theme.spacing(4)})`,
    [theme.breakpoints.up("sm")]: {
      height: "100%",
      maxHeight: "210px",
      width: `calc(${desktopCardPromotionWidth}px - ${theme.spacing(4)})`,
    },
  },
}));

export default ReorderWidget;
