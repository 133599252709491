import React, { useEffect } from "react";
import PaymentMethodsView from "./PaymentMethodsView";
import { ElementsConsumer } from "@stripe/react-stripe-js";
import { PAYMENT_ID } from "../../api-services/constants";
import StripeProvider from "../StripeProvider";
import { CircularProgress, Box } from "@mui/material";
import { navigateTo, removeParams } from "../../utils/location";

const PaymentMethodsViewWrapper = (props) => {
  const {
    order,
    user,
    openPayment,
    paymentTypeDetails,
    pageContext: { business },
    location,
    selectedBranchId
  } = props;

  useEffect(() => {
    navigateTo(
      removeParams(location, "setup_intent", "setup_intent_client_secret")
    );
  }, []);

  useEffect(() => {
    if (props.paymentTypeDetails?.paymentType !== PAYMENT_ID.STRIPE) return;

    const currency = business.openChargeCard
      ? business.openChargeCard.price.currency
      : 0;

    openPayment({
      user,
      paymentTypeIdentifier: paymentTypeDetails?.paymentType,
      currency,
      openForFuturePayment: true,
      branchId: selectedBranchId || order.branchId,
    });
  }, []);

  if (props.paymentTypeDetails?.paymentType === PAYMENT_ID.STRIPE) {
    return order.openPaymentTokens ? (
      <StripeProvider
        options={{ clientSecret: order.openPaymentTokens.paymentToken }}
        paymentTypeDetails={props.paymentTypeDetails}
      >
        <ElementsConsumer>
          {({ stripe, elements }) => (
            <PaymentMethodsView {...{ stripe, elements, ...props, selectedBranchId: selectedBranchId || order.branchId }} />
          )}
        </ElementsConsumer>
      </StripeProvider>
    ) : (
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return <PaymentMethodsView {...props} />;
};

export default PaymentMethodsViewWrapper;
