import React from "react";
import withTranslation from "../hocs/withTranslation";
import {
  setMenuItemConfiguration,
  resetMenuItemConfiguration,
  setOrderItemComponent,
  setOrderItemTopping,
  setSize,
} from "../store/menuItemsConfiguration/actions";
import { loadBranchAvailabiltyIfNeeded } from "../store/locations/actions";
import * as OrderActions from "../store/order/actions";
import { saveGiftStep } from "../store/giftRedeem/actions";

import MenuItemView from "../components/MenuItemView";
import { connect } from "react-redux";
import {
  getConfiguredMenuItemOrderData,
  getMenuItemConfiguration,
  isEditMode,
  isEditedByUser,
  getSizeOptions,
  getOrderBranch,
  getOrderSelectedServingOption,
  getStyles,
} from "../store/selectors";
const MenuItem = (props) => <MenuItemView {...props} />;

const mapStateToProps = (state, props) => {
  return {
    editItemIndex:
      isEditMode(state, props) && state.order.editingItem.itemIndex,
    sizeOptions: getSizeOptions(state, props),
    isEditMode: isEditMode(state, props),
    addToOrderToTarget: state.menuItemsConfiguration.addToOrderToTarget,
    menuItemOrderData: getConfiguredMenuItemOrderData(state, props),
    menuItemConfiguration: getMenuItemConfiguration(state, props),
    isEditedByUser: isEditedByUser(state, props),
    currentBranch: getOrderBranch(state, props),
    selectedServingOption: getOrderSelectedServingOption(state, props),
    keyboardOpen: state.app.keyboardOpen,
    editItemFromPayment: state.order.editItemFromPayment,
    giftRedeem: state.giftRedeem,
    appStyles: getStyles(state, props),
    isLoggedIn: state.user.loggedIn,
    isMobile: state.app.isMobile,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const {
    pageContext: {
      item: { id: menuItemId },
    },
  } = props;
  return {
    setState: (menuItemConfiguration) =>
      dispatch(setMenuItemConfiguration(menuItemId, menuItemConfiguration)),
    resetMenuItemConfiguration: (initialConfiguration) =>
      dispatch(resetMenuItemConfiguration(menuItemId, initialConfiguration)),
    setOrderItemComponent: (orderItemConfiguration) =>
      dispatch(setOrderItemComponent(menuItemId, orderItemConfiguration)),
    setOrderItemTopping: (orderItemTopping) =>
      dispatch(setOrderItemTopping(menuItemId, orderItemTopping)),
    setSize: (selectedMenuSizeId) =>
      dispatch(setSize(menuItemId, { selectedMenuSizeId })),
    addToOrder: (menuItemConfiguration) =>
      dispatch(OrderActions.addItem(menuItemConfiguration)),
    applyOrderItemEdit: (itemIndex, menuItemConfiguration) =>
      dispatch(
        OrderActions.applyOrderItemEdit(itemIndex, menuItemConfiguration),
      ),
    loadBranchAvailabiltyIfNeeded: () =>
      dispatch(loadBranchAvailabiltyIfNeeded()),
    saveGiftStep: (item) => dispatch(saveGiftStep(item)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(MenuItem));
