import _ from "lodash";
import { GEOMETRIC_CENTER, ROOFTOP } from "./constants";
import getPreciseDistance from "geolib/es/getPreciseDistance";
import states from "us-state-codes";

export const ACCURACY_LEVEL = {
  ROOFTOP: "rooftop",
  STREET: "street",
  PARCEL: "parcel",
  INTERPOLATED: "interpolated",
  POINT: "point",
};

export const getDistanceToBranch = ({ point, branch }, google) => {
  if (
    _.get(branch.geoPoint, "latitude") &&
    _.get(branch.geoPoint, "longitude")
  ) {
    const distance = getPreciseDistance(
      { longitude: point.lng(), latitude: point.lat() },
      branch.geoPoint,
      0.1,
    );

    return distance;
  }
  console.error(branch.name, " has no geo location");
  return Infinity;
};

export const convertPlaceToUserAddress = (place) => {
  const geoPoint = _.cloneDeep({
    latitude: place.geometry.location.lat(),
    longitude: place.geometry.location.lng(),
  });
  const isGeometricCenter =
    _.includes([GEOMETRIC_CENTER, ROOFTOP], _.get(place, "geometry.location_type"));
    console.log("IS GEOMETRIC CENTER:", _.get(place, "geometry.location_type"));
  const numberComponent = _.find(
    place.address_components,
    ({ types }) =>
      _.includes(types, "street_number") || _.includes(types, "route"),
  );
  const zipCodeComponent = _.find(place.address_components, ({ types }) =>
    _.includes(types, "postal_code"),
  );

  const zipCode = zipCodeComponent ? zipCodeComponent.short_name : null;
  const number = numberComponent ? numberComponent.short_name : null;
  const streetComponent = _.find(place.address_components, ({ types }) =>
    _.includes(types, "route"),
  );
  const street = streetComponent ? streetComponent.short_name : null;
  const cityComponent = _.find(
    place.address_components,
    ({ types }) =>
      _.includes(types, "locality") ||
      _.includes(types, "sublocality") ||
      _.includes(types, "administrative_area_level_2"),
  );
  const city = cityComponent ? cityComponent.short_name : null;
  const stateComponent = _.find(place.address_components, ({ types }) =>
    _.includes(types, "administrative_area_level_1"),
  );
  //state is not a mandatory field - should only be added if obtained
  const state = stateComponent ? stateComponent.short_name : null;

  return {
    geoPoint,
    zipCode,
    city,
    street,
    number,
    state,
    description: place.description,
    isGeometricCenter,
  };
};

export const convertMapboxPlaceToUserAddress = (place) => {
  const geoPoint = _.cloneDeep({
    longitude: place.center[0],
    latitude: place.center[1],
  });
  console.log(place);
  const isGeometricCenter =
    Boolean(place.address) &&
    _.includes(
      [
        ACCURACY_LEVEL.ROOFTOP,
        ACCURACY_LEVEL.PARCEL,
        ACCURACY_LEVEL.INTERPOLATED,
        ACCURACY_LEVEL.POINT,
      ],
      place?.properties?.accuracy,
    );
  const number = place.address;

  const zipCodeComponent = _.find(place.context, ({ id }) =>
    _.includes(id, "postcode"),
  );
  const zipCode = zipCodeComponent ? zipCodeComponent.text : null;

  const street = place.text;
  const cityComponent = _.find(place.context, ({ id }) =>
    _.includes(id, "place"),
  );
  const city = cityComponent ? cityComponent.text : null;
  const stateComponent = _.find(place.context, ({ id }) =>
    _.includes(id, "region"),
  );
  //state is not a mandatory field - should only be added if obtained
  const state = stateComponent
    ? stateNameToStateCode(stateComponent.text)
    : null;
  console.log(state);

  console.log({
    geoPoint,
    zipCode,
    city,
    street,
    number,
    state,
    description: place.place_name,
    isGeometricCenter,
  });
  return {
    geoPoint,
    zipCode,
    city,
    street,
    number,
    state,
    description: place.place_name,
    isGeometricCenter,
  };
};

export const stateNameToStateCode = (state = "") =>
  states.getStateCodeByStateName(states.sanitizeStateName(state)) || state;

export const hasHouseNumberForMapbox = (place) => Boolean(place?.address);

export const setDescriptionForAddress = (address) =>
  _.join(_.compact([address.number, address.street, address.city]), " ");
