import classnames from "classnames";
import noop from "lodash/noop";
import React from "react";
import { navigateTo } from "../../utils/location";
import openNativeLink from "../../utils/openNativeLink";
import * as styles from "./index.module.scss";
import _ from "lodash";
import { Button } from "@mui/material";

export default ({
  children,
  style,
  linkStyle,
  appStyles = {},
  slim,
  marginTop,
  loading,
  completed,
  centered,
  onClick = noop,
  to,
  spread,
  classNames,
  round,
  isExternalLink,
  label = "",
  id
}) => {
  const { Button = {}, LinkButton = {}, SlimButton = {} } = appStyles;
  const nativeMode = typeof window !== "undefined" ? window.isNativeApp : false;
  const backgroundImage = _.get(Button, "backgroundImage");
  return (
    <a
      style={{
        color: "#fff",
        ...Button,
        ...(slim && SlimButton),
        ...style,
        ...(linkStyle && LinkButton),
        ...(linkStyle && {
          color: appStyles.actionColor,
          backgroundColor: "transparent",
          boxShadow: "none",
          border: "none",
          backgroundImage: "none",
        }),
        ...(!linkStyle && backgroundImage && {backgroundImage: `url("${backgroundImage}")`, backgroundRepeat: "round", backgroundSize:"cover"})
      }}
      id={id}
      target={isExternalLink && !nativeMode && "_blank" || undefined}
      href={to ? (nativeMode ? "#" : to) : "#"}
      onClick={(e) => {
        const hrefTarget = to ? (nativeMode ? null : to) : null;
        if (hrefTarget === null && !isExternalLink) {
          e.preventDefault();
        }
        if (!(to && isExternalLink && !nativeMode)) {
          e.preventDefault();
        }
        if (!loading && !completed) {
          onClick(e);
          if (to) {
            if (isExternalLink && nativeMode) {
              console.log("open native link:", to);
              openNativeLink(to)(e);
            } else if (!(to && isExternalLink && !nativeMode)) {
              console.log("navigateTo", to);
              navigateTo(to);
            }
          }
        }
      }}
      className={classnames(
        styles.Button,
        linkStyle && styles.LinkStyle,
        "noselect",
        (centered || (appStyles.noIcons && !spread)) && styles.Centered,
        slim && styles.Slim,
        marginTop && styles.MarginTop,
        spread && styles.Spread,
        round && styles.Round,
        classNames,
      )}
      aria-labelledby={label}
      role="button"
    >
      {!loading && !completed ? children : null}
      {(loading || completed) && (
        <span
          className={classnames(
            styles.CircleLoader,
            completed && styles.LoadComplete,
          )}
        >
          {completed && (
            <span className={classnames(styles.Checkmark, styles.Draw)} />
          )}
        </span>
      )}
    </a>
  );
};

// TODO make this as base Button component after refactoring to MUI
export const ButtonMUI = ({ appStyles, style, outlined, ...props }) => {
  const baseStyles = {
    padding: "16px",
    minHeight: "60px",
    color: "#ffffff",
    fontSize: "1rem",
    width: "100%",
    "&:hover": {
      backgroundColor: appStyles.Button.backgroundColor || "inherit",
    },
    "&:active": {
      backgroundColor: appStyles.Button.backgroundColor || "inherit",
    },
  };

  return (
    <Button
      variant={outlined ? "outlined" : "contained"}
      sx={{ ...baseStyles, ...appStyles.Button, ...style }}
      {...props}
    />
  );
};
